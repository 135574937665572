import fetch from '@/utils/fetch'
// 获取站内信列表
export function imList (params) {
  return fetch({
    url: `/promotions/internal_message`,
    method: 'get',
    params
  })
}
export function imShow (id) {
  return fetch({
    url: `/promotions/internal_message/${id}`,
    method: 'get'
  })
}
export function imtemplateList (params) {
  return fetch({
    url: `/promotions/internal_message_template`,
    method: 'get',
    params
  })
}

export function imtemplateShow (id) {
  return fetch({
    url: `/promotions/internal_message_template/${id}`,
    method: 'get'
  })
}
export function imqueueList (params) {
  return fetch({
    url: `/promotions/internal_message_template_queue`,
    method: 'get',
    params
  })
}



