import fetch from '../utils/fetch'
export function getsignRule (query) {
  return fetch({
    url: '/promotions/sign/rule',
    method: 'get',
    params: query
  })
}
export function setsignRule (query) {
  return fetch({
    url: '/promotions/sign/rule',
    method: 'put',
    params: query
  })
}

